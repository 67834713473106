const config = {
  // Settings for very good security, override them if we want to...
  vgs: {
    COLLECT_URL:
      process.env.VGS_COLLECT_URL ||
      'https://js.verygoodvault.com/vgs-collect/2.18.5/vgs-collect.js',
    IDENTIFIER: process.env.VGS_IDENTIFIER || 'tntwsgyy4go',
    GOOGLE_PAY_ROUTE_ID:
      process.env.VGS_GOOGLE_PAY_ROUTE_ID ||
      'af4bb675-ee28-492d-a858-cb110b720de6', // raven
    ENVIRONMENT: process.env.VGS_ENVIRONMENT || 'sandbox',
    ORGANIZATION_ID:
      process.env.VGS_ORGANIZATION_ID || 'AC71eAdPriS4qgykmYtnc2iL',
  },
  google: {
    MERCHANT_ID: process.env.GOOGLE_MERCHANT_ID || 'BCR2DN4TXWN5RWYL',
    TEST_MERCHANT_ID:
      process.env.GOOGLE_TEST_MERCHANT_ID || '17613812255336763067', //test merchant
    MERCHANT_NAME: process.env.GOOGLE_MERCHANT_NAME || 'Demo', // test merchant
  },
  blackbird: {
    BACKEND_URL: process.env.BLACKBIRD_BACKEND_URL || 'http://localhost:8080',
  },
  cardinal: {
    DEVICE_FINGERPRINT_URL:
      process.env.CARDINAL_DEVICE_FINGERPRINT_URL ||
      'https://centinelapistag.cardinalcommerce.com/V2/Cruise/Collect',
  },
  sdk: {
    // This is needed for the parts of our code that are run via our iFrame (in development this should match your dev machine).
    PUBLIC_URL:
      process.env.SDK_PUBLIC_URL ||
      (process.env.DEPLOY_BASE_URL &&
        `${process.env.DEPLOY_BASE_URL}/sdk/v1/blackbird-web-sdk.js`) ||
      'http://localhost:1234/sdk/v1/blackbird-web-sdk.js',
  },
  segment: {
    WRITE_KEY:
      process.env.SEGMENT_WRITE_KEY || 'lOFQlMYdNaKozEYXtNxTk1qODQxXOc6R',
  },
  docs: {
    PUBLIC_KEY: process.env.DOCS_PUBLIC_KEY || 'pk_test_8f133808J4bGznOb4c45',
  },
  firebase: {
    ENDPOINT:
      process.env.FIREBASE_ENDPOINT ||
      'https://intense-torch-9195.firebaseio.com/raven/global/onlineTransactionAlert.json',
  },
  sentry: {
    STUBBED: process.env.SENTRY_STUBBED || false,
    DSN:
      process.env.SENTRY_DSN ||
      'https://11e8855047ee4302b5a367fbb01abf24@o406491.ingest.sentry.io/6676771',
    SAMPLE_RATE: 1.0,
    ENV: process.env.SENTRY_ENV || 'development',
  },
  settings: {
    MINIMUM_AMOUNT: 200,
    ENVIRONMENT: process.env.SDK_ENVIRONMENT || 'development',
  },
  blackbird_web: {
    url: process.env.BLACKBIRD_WEB_URL || 'http://localhost:3000',
  },
}

export default config
