import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
// @ts-ignore
import config from '../../../config'

const checkIfStubbed =
  (f: (...args: any[]) => any) =>
  (...args: any[]) => {
    if (config.sentry.STUBBED) {
      return
    }

    f(...args)
  }

const initialize = () => {
  Sentry.init({
    dsn: config.sentry.DSN,
    environment: config.sentry.ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: config.sentry.SAMPLE_RATE,
  })
}

const setPaymentContext = (paymentID?: string) => {
  if (paymentID) {
    Sentry.setContext('payment', { paymentID })
  }
}

const SentryLib = {
  initialize: checkIfStubbed(initialize),
  setPaymentContext: checkIfStubbed(setPaymentContext),
}

export default SentryLib
