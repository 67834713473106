import * as React from 'react'
// @ts-ignore
import {
  CardSchemaBrand,
  PopupCardSchemaHolder,
  PopupHolder,
  PopupInner,
  DropInForm,
  PayButton,
  PoweredBy,
  ErrorMessage,
  ComponentOuter,
  TotalAmountSection,
  PopupHolderSpacer,
} from './styled'
import PopupHeaderView from './PopupHeaderView'
// @ts-ignore
import { CSSTransition } from 'react-transition-group'
import Loader from '../../../../common/components/Loader'
import AlertBar from '../../../../common/components/Alert/Alert'
import SaveCardForFutureUseCheckbox from './SaveCardForFutureUse/SaveCardForFutureUseCheckbox'
import { CallbackType } from '../index'

const VisaLogo = require('../images/visa.png')
const MastercardLogo = require('../images/mastercard.png')
const AmexLogo = require('../images/amex_logo_color.png')
const poweredByImage = require('../images/fill-solid.png')
const lock = require('../images/lock.png')

interface Props {
  amount: string
  buttonText?: string
  dropInId: string
  popupTitle?: string
  popupDescription?: string
  popupImage?: string
  onCancel?: () => void
  errorMessage: string
  showErrorMessage: boolean
  showAmount: boolean
  isValid: boolean
  isSubmitting?: boolean
  onSubmit?: () => void
  dropinReady?: boolean
  shake?: boolean
  setSaveCard: CallbackType
  saveCard: boolean
  showSaveCardCheckbox: boolean
}

const PopupCardView = ({
  buttonText,
  amount,
  dropInId,
  popupTitle,
  popupImage,
  onCancel,
  errorMessage,
  showErrorMessage,
  isValid,
  isSubmitting = false,
  showAmount = true,
  onSubmit,
  dropinReady = false,
  shake = false,
  setSaveCard,
  saveCard,
  showSaveCardCheckbox,
}: Props) => {
  const showHeader = (popupTitle || popupImage || onCancel) && true

  return (
    <PopupHolderSpacer>
      <PopupHolder dropinReady={dropinReady}>
        <PopupInner shake={shake}>
          {showHeader && (
            <PopupHeaderView
              popupTitle={popupTitle}
              popupImage={popupImage}
              onCancel={onCancel}
            />
          )}
          <ComponentOuter>
            {showAmount && <TotalAmountSection>{amount}</TotalAmountSection>}
            <AlertBar />
            <PopupCardSchemaHolder>
              <CardSchemaBrand>
                <img src={VisaLogo} className="visa" alt="visa" />
              </CardSchemaBrand>
              <CardSchemaBrand>
                <img
                  src={MastercardLogo}
                  className="mastercard"
                  alt="mastercard"
                />
              </CardSchemaBrand>
              <CardSchemaBrand>
                <img src={AmexLogo} className="amex" alt="amex" />
              </CardSchemaBrand>
            </PopupCardSchemaHolder>
            <DropInForm id={dropInId}>
              {showSaveCardCheckbox && (
                <SaveCardForFutureUseCheckbox
                  setSaveCard={setSaveCard}
                  saveCard={saveCard}
                />
              )}
            </DropInForm>
            <CSSTransition
              in={showErrorMessage}
              timeout={10}
              classNames="popup-error-message"
            >
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </CSSTransition>

            <PayButton
              disabled={!isValid}
              onClick={() => !isSubmitting && onSubmit()}
            >
              {isSubmitting ? (
                <Loader />
              ) : (
                <div>
                  <img alt="secure" height="16" src={lock} />{' '}
                  <strong>
                    {buttonText || `PAY ${showAmount ? '' : amount}`}
                  </strong>
                </div>
              )}
            </PayButton>
            <PoweredBy>
              Secured by&nbsp;
              <img alt="" height="40" src={poweredByImage} />
            </PoweredBy>
          </ComponentOuter>
        </PopupInner>
      </PopupHolder>
    </PopupHolderSpacer>
  )
}

export default PopupCardView
