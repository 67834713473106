/** The complete token flow handles the process of completing a payment based on card information collected from
 * flex form and the payment Id
 * */
import { Deferred } from '../../../../common/utils/Deferred'
import BlackbirdHandler from '../../../../common/lib/blackbirdHandler'
import { Mode } from '../../../checkout/models/CheckoutConfiguration'
import DeviceFingerprintHandler from '../../security/deviceFingerPrint/deviceFingerprintHandler'
import { Segment } from '../../../../common/lib/segment/Segment'

// @ts-ignore
export default class CardCompletePaymentFlow {
  constructor(
    configuration,
    extraRequestData,
    vgsSecureForm,
    cardBrand,
    cardLast4,
    bin
  ) {
    this.configuration = configuration
    this.extraRequestData = extraRequestData
    this.deferred = new Deferred()
    this.cardBrand = cardBrand
    this.cardLast4 = cardLast4
    this.bin = bin
    this.vgsSecureForm = vgsSecureForm
    this.#runFlowWithTokenizedCardDetails()
  }

  /** run the flow and get a charge token for the given card details*/
  #runFlowWithTokenizedCardDetails = () => {
    return this.#tokenizeCardData().then((cardData) => {
      return Promise.all([
        this.#captureDeviceFingerprint(
          cardData,
          this.bin,
          this.configuration,
          this.extraRequestData
        ),
        this.#completePaymentWithPaymentMethod(
          {
            cardToken: cardData.cardNumber,
            cardCvv: cardData.cardCvv,
            cardExpiry: cardData.cardExpiry,
            brand: this.cardBrand,
            last4: this.cardLast4,
            bin: this.bin,
          },
          this.configuration,
          this.extraRequestData
        ),
      ])
        .then((result) => {
          return this.#maybePerformRedirectURlNextAction(result)
        })
        .then(this.deferred.resolve)
        .catch(this.deferred.reject)
    })
  }

  /** Send a request to vgs with our card data that will return it in tokenized form */
  #tokenizeCardData = () =>
    new Promise((resolve, reject) => {
      return this.vgsSecureForm.submit(
        '/post',
        {
          serializer: 'deep',
        },
        (status, response) => {
          const { cardNumber, cardCvv, cardExpiry } = response.json
          resolve({ cardNumber, cardCvv, cardExpiry })
        },
        (errors) => {
          reject({
            errorType: 'vgs_tokenize_error',
            message: 'Please fix field related errors before submitting.',
            fieldErrors: errors,
          })
        }
      )
    })

  #usable = (mode, usable) => {
    const usableExtractedFromMode =
      mode === Mode.subscription ? 'offline' : usable || 'online'

    return mode ? usableExtractedFromMode : usable
  }
  #save = (mode, usable, save) => {
    const saveExtractedFromMode = usable === 'offline' || save

    return mode ? saveExtractedFromMode : save
  }

  #captureDeviceFingerprint = (
    cardData,
    bin,
    configuration,
    extraRequestData
  ) => {
    Segment.track('DEVICE_FINGERPRINT_CAPTURING_STARTED', {
      paymentId: configuration.id,
    })
    const blackbirdHandler = new BlackbirdHandler(configuration.key)
    return blackbirdHandler
      .getAccessTokenForPayment({
        paymentId: extraRequestData.id,
        merchantId: configuration.businessId,
        idempotencyKey: extraRequestData.idempotencyKey,
      })
      .then(({ accessToken, paymentId }) => {
        return DeviceFingerprintHandler.captureDeviceFingerprint({
          accessToken,
          bin,
          cardData,
          paymentId,
        })
      })
      .then(({ accessToken, bin, cardData }) => {
        return { accessToken, bin, cardData }
      })
  }

  #completePaymentWithPaymentMethod = (
    { cardToken, cardCvv, cardExpiry, brand, last4, bin },
    configuration,
    extraRequestData
  ) => {
    const {
      key,
      businessId,
      metadata,
      mode = Mode.payment,
    } = configuration
    const {
      id: paymentId,
      customer,
      paymentMethodDetails,
      idempotencyKey,
    } = extraRequestData

    const usable = this.#usable(mode, paymentMethodDetails?.usable)

    const save = this.#save(mode, usable, paymentMethodDetails?.save)
    const completePaymentRequestBody = {
      ...(customer ? { customer } : {}),
      mode,
      paymentMethodDetails: {
        type: 'card',
        save,
        card: {
          cardNumberToken: cardToken,
          bin: bin,
          last4: last4,
          scheme: brand,
          cvc: cardCvv,
          expiryMonth: cardExpiry.split('/')[0],
          expiryYear: cardExpiry.split('/')[1],
          usable,
        },
      },
      metadata,
    }

    const blackbirdHandler = new BlackbirdHandler(key)
    return blackbirdHandler.completePayment({
      paymentId: paymentId,
      body: completePaymentRequestBody,
      merchantId: businessId,
      idempotencyKey: idempotencyKey,
    })
  }

  #maybePerformRedirectURlNextAction = (result) => {
    const deviceFingerprintResult = result[0]
    const completePaymentResult = result[1]
    if (completePaymentResult.status === 'pending') {
      return this.#completePaymentWithNextActionAnswer(
        {
          answerResult: deviceFingerprintResult.accessToken,
          answerType: 'device_fingerprint',
        },
        this.configuration,
        this.extraRequestData
      )
    } else {
      return completePaymentResult
    }
  }

  //Todo make new method that takes in parameters and then use blackbird handler complete payment method, also set bin from next action that comes back from device fingerprint

  #completePaymentWithNextActionAnswer = (
    { answerResult, answerType },
    configuration,
    extraRequestData
  ) => {
    const {
      key,
      businessId,
      metadata,
      mode = Mode.payment,
      nextActionContainer,
    } = configuration
    const { id: paymentId, customer, idempotencyKey } = extraRequestData

    //Todo build payload dynamically based off answer type
    const completePaymentRequestBody = {
      ...(customer ? { customer } : {}),
      mode,
      answer: {
        type: answerType,
        deviceFingerprint: answerResult,
      },
      metadata,
    }

    const blackbirdHandler = new BlackbirdHandler(key)
    return blackbirdHandler.completePaymentAndProcessNextAction({
      paymentId,
      body: completePaymentRequestBody,
      merchantId: businessId,
      idempotencyKey,
      embeddedContainer: nextActionContainer,
    })
  }

  promise = () => {
    return this.deferred.promise
  }
}
