import config from '../../../config'
import api from '../api'

export default class BlackbirdApi {
  constructor(publicKey) {
    this.publicKey = publicKey
  }

  getAccessToken = ({ paymentId, merchantId, idempotencyKey }) => {
    return api(
      `${config.blackbird.BACKEND_URL}/payments/${paymentId}/accessToken/`,
      this.publicKey,
      merchantId,
      idempotencyKey,
      {
        method: 'GET',
      }
    ).then((getAccessTokenResponse) => {
      if (!getAccessTokenResponse) {
        throw {
          errorType: 'access_token_error',
          message:
            getAccessTokenResponse?.description ||
            'Something went wrong, please retry again later',
          data: getAccessTokenResponse,
          status: 'failed',
        }
      } else {
        return getAccessTokenResponse
      }
    })
  }

  completePaymentRequest = ({
                              paymentId,
                              body,
                              merchantId,
                              idempotencyKey,
                              base_url = config.blackbird.BACKEND_URL
                            }) => {
    return api(
      `${base_url}/payments/${paymentId}/complete/`,
      this.publicKey,
      merchantId,
      idempotencyKey,
      {
        method: 'POST',
        body: body,
      }
    ).then((completePaymentResponse) => {
      if (
        !completePaymentResponse ||
        !completePaymentResponse?.id ||
        !completePaymentResponse?.status
      ) {
        throw {
          errorType: 'source_error',
          message:
            completePaymentResponse?.description ||
            'Something went wrong, please contact support',
          data: completePaymentResponse,
          status: 'failed',
        }
      } else {
        return completePaymentResponse
      }
    })
  }
}
