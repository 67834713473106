import React, { useRef, useState } from 'react'
import './FieldCard.scss'
import BaseCardView from '../../BaseCard/BaseCardView'
import deepmerge from 'deepmerge'

const FieldCard = ({ formInfo }) => {
  const { flexFields } = formInfo

  const { cardNumberField, cardCvvField, cardExpiryField } = flexFields
  const [allowShortCardNumber, setAllowShortCardNumber] = useState(false)
  const cardViewRef = useRef()

  const isMobileWidth = () => cardViewRef.current.clientWidth < 370

  const onFieldStateUpdate = () => {
    const nonCardNumberFieldFocused =
      cardCvvField.state.focus || cardExpiryField.state.focus
    const nonCardNumberFieldHasContents =
      !cardExpiryField.state.empty || !cardCvvField.state.empty

    const _allowShortCardNumber =
      isMobileWidth() &&
      !cardNumberField.state.focus &&
      (nonCardNumberFieldFocused || nonCardNumberFieldHasContents)

    // we only really want to update things if we have to, otherwise we are just wasting time
    if (_allowShortCardNumber !== allowShortCardNumber) {
      if (_allowShortCardNumber) {
        // update the styles of ur
        cardNumberField.updateStyle(
          deepmerge(
            {
              base: {
                wordSpacing: '400px',
                marginLeft: '20px',
              },
            },
            cardNumberField.currentStyle()
          )
        )
      } else {
        cardNumberField.updateStyle(
          deepmerge(
            {
              base: {
                wordSpacing: 'inherit',
                direction: 'inherit',
                marginLeft: '0px',
              },
            },
            cardNumberField.currentStyle()
          )
        )
      }

      // deal with the updates to our fields states in some fashion
      setAllowShortCardNumber(_allowShortCardNumber)
    }
  }

  cardNumberField?.on('change', onFieldStateUpdate)
  cardExpiryField?.on('change', onFieldStateUpdate)
  cardCvvField?.on('change', onFieldStateUpdate)

  //isMobileWidth

  const shortCardNumberStyling = ''

  return (
    <div
      className={`yc-field-dropin-layout ${shortCardNumberStyling}`}
      ref={cardViewRef}
    >
      <BaseCardView formInfo={formInfo} showCvvTooltip={false} />
    </div>
  )
}

export const FieldCardViewOptions = Object.freeze({
  cardNumberOptions: {
    placeholder: 'Card number',
    style: {
      base: {
        // this is not supported by our css approach so we can pass it in here.
        padding: 0,
      },
    },
    showCardIcon: {
      left: '0px',
    },
    schemaOverrides: [
      {
        type: 'mastercard',
        pattern:
          /^(5[1-5][0-9]{4}|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720|2265)([0-9]{2})/,
        format: /(\d{1,4})/g,
        length: [16],
        cvcLength: [3],
        luhn: true,
      },
    ],
  },
  cardExpiryOptions: {
    placeholder: 'MM / YY',
  },
  cardCvvOptions: {
    placeholder: 'CVV',
    showCardIcon: false,
  },
})

export default FieldCard
