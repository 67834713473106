import React from 'react'
import ReactDOM from 'react-dom'
import GooglePayButton from '@google-pay/button-react'
import config from '../../../config'
import { Mode } from '../checkout/models/CheckoutConfiguration'
import BlackbirdHandler from '../../common/lib/blackbirdHandler'
import { Segment } from '../../common/lib/segment/Segment'
import DeviceFingerprintHandler from '../flex/security/deviceFingerPrint/deviceFingerprintHandler'

export default class GooglePay {
  constructor({
    publicKey,
    businessId,
    paymentId,
    nextActionContainer,
    processingMode,
  }) {
    this.mode = Mode.payment

    this.environment =
      config.settings.ENVIRONMENT === 'production' && processingMode === 'live'
        ? 'PRODUCTION'
        : 'TEST'
    this.publicKey = publicKey
    this.businessId = businessId
    this.paymentId = paymentId
    this.nextActionContainer = nextActionContainer
    this.processingMode = processingMode

    this.blackbirdHandler = new BlackbirdHandler(this.publicKey)
  }

  // Used by checkout-client to fire events on success or failure
  setSuccessFunction = (handler) => {
    this.successFunction = handler
  }

  setFailureFunction = (handler) => {
    this.failureFunction = handler
  }

  setLoadingFunction = (handler) => {
    this.loadingFunction = handler
  }

  setCancelFunction = (handler) => {
    this.cancelFunction = handler
  }

  setGooglePayButtonShowingFunction = (handler) => {
    this.googlePayButtonShowingFunction = handler
  }

  mount = (amountInCents, containerId, style, buttonRadius) => {
    const paymentRequest = this.#createPaymentRequest(amountInCents)

    const googlePayButton = (
      <GooglePayButton
        environment={this.isProductionLive() ? 'PRODUCTION' : 'TEST'}
        buttonSizeMode="fill"
        style={style}
        buttonType="plain"
        buttonRadius={buttonRadius}
        paymentRequest={paymentRequest}
        onLoadPaymentData={(paymentRequest) => {
          this.loadingFunction(true)
          this.#processPayment(paymentRequest)
        }}
        onReadyToPayChange={(readyToPayChange) => {
          this.googlePayButtonShowingFunction(readyToPayChange.isReadyToPay)
        }}
      />
    )

    // Render button into the specified container
    const container = document.getElementById(containerId)
    if (container) {
      ReactDOM.render(googlePayButton, container)
    } else {
      console.error(`Container with ID '${containerId}' not found`)
    }
  }

  #createPaymentRequest = (amountInCents) => {
    return {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY'],
            allowedCardNetworks: ['AMEX', 'MASTERCARD', 'VISA'],
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              gateway: 'verygoodsecurity',
              gatewayMerchantId: config.vgs.ORGANIZATION_ID,
            },
          },
        },
      ],
      merchantInfo: {
        merchantId: this.isProductionLive()
          ? config.google.MERCHANT_ID
          : config.google.TEST_MERCHANT_ID,
        merchantName: config.google.MERCHANT_NAME,
      },
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPriceLabel: 'Total',
        totalPrice: this.isProductionLive()
          ? (amountInCents / 100).toFixed(2)
          : '0', // Round to two decimal places as a string, must be 0 for test env
        currencyCode: 'ZAR',
        countryCode: 'ZA',
      },
    }
  }

  isProductionLive() {
    return (
      config.settings.ENVIRONMENT === 'production' &&
      this.processingMode === 'live'
    )
  }

  #processPayment = (paymentData) => {
    const paymentToken = JSON.parse(
      paymentData.paymentMethodData.tokenizationData.token
    )

    this.#completePaymentWithPaymentMethod({
      paymentToken,
      businessId: this.businessId,
      paymentId: this.paymentId,
      last4: paymentData.paymentMethodData.info.cardDetails,
      scheme: paymentData.paymentMethodData.info.cardNetwork.toLowerCase(), // to match blackbird enum
    })
      .then((completePaymentResult) => {
        // if the payment is already succeeded, do not start the 3ds flow
        if (completePaymentResult.status === 'succeeded') {
          return this.successFunction()
        }

        if (completePaymentResult.error) {
          return this.cancelFunction({
            errorType: completePaymentResult.error.type,
            message: completePaymentResult.error.description,
            code: completePaymentResult.error.code,
          })
        }

        return this.#captureDeviceFingerprint(completePaymentResult.bin)
          .then((deviceFingerprintResult) => {
            return this.#maybePerformRedirectURlNextAction({
              completePaymentResult: completePaymentResult,
              deviceFingerprintResult: deviceFingerprintResult,
            })
          })
          .then(({ error }) => {
            this.loadingFunction(false)
            if (error) {
              this.cancelFunction(error)
            } else {
              this.successFunction()
            }
          })
          .catch(this.failureFunction)
      })
      .catch(this.failureFunction)
  }

  #completePaymentWithPaymentMethod = ({
    paymentToken,
    businessId,
    paymentId,
    last4,
    scheme,
  }) => {
    const completePaymentRequestBody = {
      mode: this.mode,
      paymentMethodDetails: {
        type: 'google_pay',
        googlePay: {
          token: paymentToken,
          last4,
          scheme,
        },
      },
    }

    return this.blackbirdHandler.completePayment({
      paymentId,
      body: completePaymentRequestBody,
      merchantId: businessId,
      idempotencyKey: null,
      proxy: true,
    })
  }

  #captureDeviceFingerprint = (bin) => {
    Segment.track('DEVICE_FINGERPRINT_CAPTURING_STARTED', {
      paymentId: this.paymentId,
    })
    const blackbirdHandler = new BlackbirdHandler(this.publicKey)
    return blackbirdHandler
      .getAccessTokenForPayment({
        paymentId: this.paymentId,
        merchantId: this.businessId,
        idempotencyKey: null,
      })
      .then(({ accessToken, paymentId }) => {
        return DeviceFingerprintHandler.captureDeviceFingerprint({
          accessToken,
          bin,
          cardData: undefined,
          paymentId,
        })
      })
      .then(({ accessToken }) => {
        return { accessToken }
      })
  }

  #maybePerformRedirectURlNextAction = ({
    completePaymentResult,
    deviceFingerprintResult,
  }) => {
    if (completePaymentResult.status === 'pending') {
      return this.#completePaymentWithNextActionAnswer({
        deviceFingerprint: deviceFingerprintResult.accessToken,
        answerType: 'device_fingerprint',
      })
    } else {
      return completePaymentResult
    }
  }

  #completePaymentWithNextActionAnswer = ({
    deviceFingerprint,
    answerType,
  }) => {
    const completePaymentRequestBody = {
      mode: this.mode,
      answer: {
        type: answerType,
        deviceFingerprint,
      },
    }

    return this.blackbirdHandler.completePaymentAndProcessNextAction({
      paymentId: this.paymentId,
      body: completePaymentRequestBody,
      merchantId: this.businessId,
      idempotencyKey: null,
      embeddedContainer: this.nextActionContainer,
    })
  }
}
