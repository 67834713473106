import { isSSR } from './utils/utils'
import { v4 as uuidv4 } from 'uuid'

!isSSR && require('whatwg-fetch')

/** Function that makes calls to core style apis and is able to unpack error messages and success messages*/
const api = (url, key, merchantId, idempotencyKey, params) => {
  return new Promise((resolve) => {
    let headers = {
      Accept: 'application/json',
      'Content-Type': params.contentType || 'application/json',
      Authorization: `Bearer ${key}`,
    }
    if (merchantId) {
      headers['Sub-Merchant-Id'] = merchantId
    }
    headers['Idempotency-Key'] = idempotencyKey || uuidv4()
    return fetch(url, {
      headers: headers,
      method: params.method,
      body: params.body,
      mode: params.mode,
    })
      .then((response) => {
        if (response.status < 300 && response.status >= 200) {
          let result = jsonFromResponse(response)
          result.then((result) => {
            console.log(
              `${response.status}> ${params.method} ${url}: ${result}`
            )
          })
          resolve(result)
        } else {
          response.json().then((json) => {
            const message =
              json.description || 'Something went wrong, please contact support'

            resolve({
              status: response.status,
              message: message,
            })
          })
        }
      })
      .catch((error) => {
        console.warn(`API> Error on ${requestSummary(url, params)}> ${error}`)

        resolve({
          status: 501,
          message: 'Something went wrong, please contact support',
        })
      })
  })
}

/** A summary of the request details*/
const requestSummary = (url, params) => `${params.method} ${url}`

/**
 * Get a json from a response and if you can not create an error message that will be easier to handle
 * as it follows the same format as what would have come back from the server
 */
const jsonFromResponse = (response) => {
  return response
    .json()
    .then((json) => json)
    .catch((error) => ({
      status: 501,
      message:
        'An unexpected error occurred (server returned invalid response)',
      error,
    }))
}

export default api
