// @ts-ignore
import $ from 'domtastic'
// @ts-ignore
import FlexForm from './flex/FlexForm/'
// @ts-ignore
import CardDropin from './dropin/CardDropin'
// @ts-ignore
import EFT from './eft/index'
import Events from './Events'
import CheckoutHandler from './checkout/CheckoutHandler'
// @ts-ignore
import ApplePay from './apple-pay/index'
// @ts-ignore
import config from '../../config'
// @ts-ignore
import { injectWebpackStyles } from '../common/utils/utils'
import CheckoutConfiguration, {
  DisplayMethod,
} from './checkout/models/CheckoutConfiguration'
import SubmitParameters, {
  PaymentType,
} from './checkout/models/SubmitParameters'
import SentryLib from '../common/lib/Sentry'

// @ts-ignore
import SavedCardCompletePaymentFlow from './flex/FlexForm/flow/SavedCardCompletePaymentFlow'
import MountParameters from './checkout/models/MountParameters'
import { Segment } from '../common/lib/segment/Segment'

// @ts-ignore
import GooglePay from './google-pay/index'

SentryLib.initialize()

export default class BlackbirdSDK {
  static Events = Events
  private configuration: CheckoutConfiguration

  constructor(configuration: CheckoutConfiguration) {
    this.configuration = configuration

    SentryLib.setPaymentContext(configuration?.id)
    Segment.track('SDK_INITIALISED', {
      paymentId: configuration.id,
      publicKey: configuration.publicKey,
    })

    if (configuration.onNextActionBegun) {
      FlexForm.on(Events.NEXT_ACTION_BEGUN, configuration.onNextActionBegun)
    }
  }

  /** Create a flex form so that you can custom build your form the way you want to*/
  flex(configuration: CheckoutConfiguration) {
    SentryLib.setPaymentContext(configuration?.id)

    return new FlexForm({
      ...configuration,
      key: this.configuration.publicKey,
    })
  }

  //@ts-ignore
  eft(configuration: CheckoutConfiguration) {
    SentryLib.setPaymentContext(configuration?.id)

    return new EFT(
      this.configuration.publicKey,
      this.configuration.eftContainer || this.configuration.nextActionContainer,
      this.configuration.businessId || configuration?.businessId
    )
  }

  /** Create a full working form so that you can custom build your form the way you want to*/
  inline(configuration: CheckoutConfiguration) {
    SentryLib.setPaymentContext(configuration?.id)
    return new CardDropin({
      ...configuration,
      key: this.configuration.publicKey,
    })
  }

  applePay(configuration: CheckoutConfiguration) {
    SentryLib.setPaymentContext(configuration?.id)

    return new ApplePay(
      this.configuration.publicKey,
      this.configuration.businessId || configuration?.businessId
    )
  }

  googlePay(configuration: CheckoutConfiguration) {
    SentryLib.setPaymentContext(configuration?.id)

    return new GooglePay(
        config.sentry.ENV,
    )
  }

  /** Create a popup checkout form that is fully designed and ready to be shown to your user.  Under the surface it will make
   * use of one of our other SDKs to produce its content.*/
  popup(configuration: CheckoutConfiguration) {
    SentryLib.setPaymentContext(configuration?.id)
    //This is to support older versions of the plugin that still supply the cancel handler in the onClose field
    return new CheckoutHandler({
      ...configuration,
      ...this.configuration,
    })
  }

  mount(parameters: MountParameters) {
    this.configuration.eftContainer = parameters.eftContainer
    this.configuration.nextActionContainer = parameters.nextActionContainer
  }

  showPopup(configuration: CheckoutConfiguration) {
    SentryLib.setPaymentContext(configuration?.id)
    const checkoutHandler = new CheckoutHandler({
      ...configuration,
      ...this.configuration,
      displayMethod: DisplayMethod.Manual,
    })

    checkoutHandler.showPopup()

    return checkoutHandler
  }

  submit(parameters: SubmitParameters) {
    Segment.track('SDK_SUBMIT_EVENT', {
      paymentId: parameters?.id,
      paymentType: parameters?.paymentType,
    })
    SentryLib.setPaymentContext(parameters?.id)
    switch (parameters.paymentType) {
      case PaymentType.EFT:
        return this.eft(this.configuration).submit(parameters)
      case PaymentType.Card:
        const savedCardCompletePaymentFlow = new SavedCardCompletePaymentFlow(
          this.configuration,
          parameters
        )
        const flow = savedCardCompletePaymentFlow.promise()
        return flow.then(parameters.callback)
    }
  }

  /** @deprecated please use inline in future*/
  dropin(configuration: CheckoutConfiguration) {
    return this.inline(configuration)
  }

  //Todo remove validation
  /** @deprecated please use popup in future*/
  checkout(configuration: CheckoutConfiguration) {
    this.popup(configuration)
  }

  static FlexForm = FlexForm
}

// @ts-ignore
window.BlackbirdSDK = BlackbirdSDK

// This has to be done this way to insure that regardless of the framework used it works.
setTimeout(injectWebpackStyles, 100)
$(document).ready(injectWebpackStyles)
