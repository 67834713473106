import React from 'react'
import ReactDOM from 'react-dom'
import GooglePayButton from '@google-pay/button-react'
import config from '../../../config'

export default class GooglePay {
  constructor(mode) {
    this.environment = mode === 'production' ? 'PRODUCTION' : 'TEST'
  }

  mount = (amountInRands, containerId, style, buttonRadius) => {
    const paymentRequest = this.#createPaymentRequest(amountInRands)

    const googlePayButton = (
      <GooglePayButton
        environment={this.environment}
        buttonSizeMode='fill'
        style={style}
        buttonType="plain"
        buttonRadius={buttonRadius}
        paymentRequest={paymentRequest}
        onLoadPaymentData={(paymentRequest) => {
          this.#processPayment(paymentRequest)
        }}
      />
    )

    // Render button into the specified container
    const container = document.getElementById(containerId)
    if (container) {
      ReactDOM.render(googlePayButton, container)
    } else {
      console.error(`Container with ID '${containerId}' not found`)
    }
  }

  #createPaymentRequest = (amountInRands) => {
    return {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY'],
            allowedCardNetworks: ['AMEX', 'MASTERCARD', 'VISA'],
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              gateway: 'verygoodsecurity',
              gatewayMerchantId: config.vgs.GOOGLE_PAY_IDENTIFIER,
            },
          },
        },
      ],
      merchantInfo: {
        merchantId: config.vgs.MERCHANT_ID,
        merchantName: config.vgs.MERCHANT_NAME,
      },
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPriceLabel: 'Total',
        totalPrice: amountInRands, // string value
        currencyCode: 'ZAR',
        countryCode: 'ZA',
      },
    }
  }

  #processPayment = (paymentData) => {
    console.log('Processing payment: ', paymentData)
    // TODO: OPL-3380 Implement further payment processing as needed
  }
}
